
import { Vue, Options } from 'vue-class-component';
import vueFilePond from 'vue-filepond';
import dayjs from 'dayjs';
import 'filepond/dist/filepond.min.css';
import get from 'lodash-es/get';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import {
  BaseIcon,
  BaseSelect,
  BaseButton,
  BaseTextInput,
  BaseModal,
  BaseDatePicker
} from '@/lib/components';
import { Consult, ExternalPatientReference, Patient } from '@/models';
import { getDobFromISOString, verifyMrn } from '@/helpers/patient.helper';
import { UHB_NHS_NUMBER_KEY } from '@/constants';
import { formattedNHSNumber } from '@/lib/helpers/nhs-number.helper';
import { i18n } from '@/i18n/i18n';
import { ConsultService } from '@/services/api';
import { usePatientStore } from '@/stores/patient.store';
import { useNotificationStore } from '@/stores/notification.store';

const FilePond = vueFilePond(FilePondPluginFileValidateType);

@Options({
  props: {
    title: {
      type: String,
      default() {
        return i18n.global.t('custom.uhb.consult.assign');
      }
    },
    canEditPatientMrn: {
      type: Boolean,
      default: false
    },
    consult: {
      type: Object,
      default: () => ({})
    },
    patient: {
      type: Object,
      default: () => ({})
    },
    clinicTypeOptions: {
      type: Array,
      default: () => []
    }
  },
  components: {
    BaseIcon,
    BaseDatePicker,
    BaseModal,
    BaseButton,
    BaseSelect,
    BaseTextInput,
    FilePond
  }
})
export default class AssignConsultModal extends Vue {
  consult!: Consult;
  patient!: Patient;
  canEditPatientMrn!: boolean;
  patientStore = usePatientStore();
  notificationStore = useNotificationStore();

  loading = false;
  scheduled_at = '';
  scheduled_location = '';

  errors: { [key: string]: string[] } = {};

  consultService = new ConsultService();
  // Patient MRN
  patientMrnAtClinicProvider = '';

  fileAttachmentId: number | null = null;

  mounted() {
    this.startSyncingPatientMrnAtClinicProvider();
  }

  startSyncingPatientMrnAtClinicProvider() {
    this.syncPatientMrnAtClinicProvider();
    this.$watch(
      'consult',
      this.syncPatientMrnAtClinicProvider,
      {
        deep: true
      }
    );
  }

  syncPatientMrnAtClinicProvider() {
    this.patientMrnAtClinicProvider = this.consult?.patient_mrn_at_clinic_provider || '';
  }

  get showNhsNumber() {
    return this.patientStore.showNhsNumber;
  }

  get patientNHSNumber(): string {
    if (this.patient && this.patient.external_patient_references) {
      const nhsEpr = this.patient.external_patient_references.find(
        (epr: ExternalPatientReference) =>
          epr.type ? epr.type.key === UHB_NHS_NUMBER_KEY : false
      );

      if (nhsEpr && nhsEpr.value) {
        return formattedNHSNumber(nhsEpr.value);
      }
    }
    return this.$t('platform.common.none').toString();
  }

  get consultTypeDisplayLabel() {
    return get(this.consult, 'consult_type.name', '');
  }

  get isLocationVisible() {
    const providerName = this.consult
      ? this.consult.clinic.provider?.name
      : null;
    if (
      providerName !==
      this.$t('platform.common.health-harmonie-organisation-label')
    ) {
      return false;
    }

    return true;
  }

  get today() {
    return dayjs().format('YYYY-MM-DD');
  }

  get validateSelection() {
    return (
      this.scheduled_at &&
      (this.scheduled_at >= this.today) &&
      (this.scheduled_location || !this.isLocationVisible) &&
      this.patientMrnAtClinicProvider
    );
  }

  close() {
    this.scheduled_at = '';
    this.scheduled_location = '';

    this.patientMrnAtClinicProvider = '';
    this.$emit('close');
  }

  async updateConsult() {
    try {
      this.loading = true;
      if (
        this.patientMrnAtClinicProvider &&
        !verifyMrn(this.patientMrnAtClinicProvider)
      ) {
        this.errors = {
          ...this.errors,
          patientMrnAtClinicProvider: [
            this.$t('custom.uhb.consult.spaces-not-allowed') as string
          ]
        };
        return;
      }
      const data = {
        scheduled_at: this.scheduled_at,
        scheduled_location: this.scheduled_location,
        ...this.canEditPatientMrn && {
          patient_mrn_at_clinic_provider: this.patientMrnAtClinicProvider
        }
      };
      await this.consultService.update(this.consult.id, data);
      this.$emit('update-patient');
      this.close();
    } catch (error) {
      if (error.response.status === 422) {
        this.errors = error.response.data.errors;
      } else {
        this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.update-error'),
          label: error.response.data.message
        });
      }
    } finally {
      this.loading = false;
    }
  }

  formatDob(dob: string) {
    return this.$d(getDobFromISOString(dob), 'short');
  }
}
